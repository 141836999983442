import { createPinia } from 'pinia'

import AccountApp from '../../vue/apps/account';

const pinia = createPinia();

$(document).ready(() => {
    const accApp = document.getElementById('myAccountApp');
    
    if(accApp){
        new AccountApp(accApp, pinia);
    }
});
