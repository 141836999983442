<template>
    <form data-ajax-form validate>
        <input type="hidden" name="action" value="reset_password" />
        <input type="hidden" name="nonce"  :value="nonce" />
        <input type="hidden" name="uid"    :value="userID" />
        <input type="hidden" name="email"  :value="email" />

        <div data-form-msg></div>
        <fieldset>
            <legend>User Info</legend>

           <PasswordFields />

            <fieldset>
                <legend>Recaptcha</legend>
                <div style="float:right;" class="g-recaptcha" :data-sitekey="siteKey"></div>
            </fieldset>

            <fieldset class="alignRight">
                <legend>Submit</legend>
                <button type="submit" class="btn btn-primary btn-large" data-submit-btn>Submit</button>
            </fieldset>

        </fieldset>
    </form>

</template>

<script>
import { mapState } from 'pinia';
import { accountStore } from "../stores/account.js";

import PasswordFields from "../forms/PasswordFields.vue";

export default {
    name: "PasswordResetForm",
    components: {
        PasswordFields
    },
    props: {
        nonce: {
            type: String,
            required: true
        },
        siteKey: {
            type: String,
            required: true
        },
        userID:{
            type: Number,
            required: true
        },
        email:{
            type: Number,
            required: true
        },
    },
    computed: {
    },
};
</script>

<style>
</style>
