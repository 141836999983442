<template>
    <div :class="classString" id="initialPasswordField">
        <label for="password">Password: <mark>*</mark></label>
        <input type="password" id="password" name="password" placeholder="Password" required
            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" />
    </div>

    <div :class="classString2" data-match-value="initialPasswordField">
        <label for="confirmPassword">Confirm Password: <mark>*</mark></label>
        <input type="password" id="confirmPassword" name="confirm_password" placeholder="Confirm Password" required
            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" />
    </div>

    <div class="passRequirements">
        <small>All Passwords should be alphnumeric and contain only the following special characters, <code>(.,!?|)</code>. Each password should contain at least one letter, one number, and a special character. Each password must be at least 8 characters long.</small>
    </div>

</template>

<script>
import { mapState }    from 'pinia';
import { globalStore } from "../stores/global.js";
import { loginStore }  from "../stores/login.js";

export default {
    name: "LoginForm",
    components: {},
    props: {
        nonce: {
            type: String,
            required: true
        },
        siteKey: {
            type: String,
            required: true
        },
        field1Classes:{
            type: String,
            default: ''
        },
        field2Classes:{
            type: String,
            default: ''
        }
    },
    mounted() {},
    methods: {
        classString(){
            return 'inputControl ' + this.field1Classes
        },
        classString2(){
            return 'inputControl ' + this.field2Classes
        }
    },
    computed: {
        ...mapState(loginStore, {
            nonce: store => store.nonces.login
        }),
        ...mapState(globalStore, {
            isUserAuthenticated: store => store.isUserAuthenticated,
            siteKey: store => store.siteKey
        })
    },
};
</script>

<style>
</style>
