import { defineStore } from 'pinia';

export const accountStore = defineStore('accountStore', {
    state: () => {
        return {
            favoriteListings: [],
            detailsLoaded: false,
            nonce: account.nonces.reset,
            user:{
                username: null,
                email: null,
                firstName: null,
                lastName: null
            }
        }
    },
    actions: {
        loadFavoriteData: function () {
            const self = this;
            const url  = account.ajaxUrl;

            let data = {
                action: 'favorite_listings',
            }

            // Make request to backend
            $.ajax({
                method: 'POST',
                url: url,
                data: data,
            }).then(function (resp) {
                const response = JSON.parse(resp);

                if (response.status) {
                    self.$patch({
                        favoriteListings: response.data.listings
                    })
                }
            }).catch(function (err) {
                console.log(err)
            })
        },
        loadUserData: function(){
            const self = this;
            const url  = account.ajaxUrl;

            let data = {
                action: 'user_info',
                uid: wpCore.user.uid
            }

            // Make request to backend
            $.ajax({
                method: 'POST',
                url: url,
                data: data,
            }).then(function (resp) {
                const response = JSON.parse(resp);

                if (response.status) {
                    self.$patch({
                        detailsLoaded: true,
                        user: {
                            username: response.data.username,
                            email: response.data.email,
                            firstName: response.data.firstName,
                            lastName: response.data.lastName
                        }
                    })
                }
            }).catch(function (err) {
                console.log(err)
            })
        }
    }
});