<template>
    <p>If you have recently been helped by Starkey Properties, and would like to leave a comment or testimonial, please fill out the form below. </p>
    <form data-ajax-form validate>
        <input type="hidden" name="action" value="submit_a_testimonial" />
        <input type="hidden" name="nonce" :value="nonce" />

        <div data-form-msg></div>
        <fieldset>
            <legend>User Info</legend>

            <div class="inputControl half">
                <label for="firstName">First Name: <mark>*</mark></label>
                <input type="text" 
                       id="firstName" 
                       name="firstName" 
                       placeholder="First Name" 
                       required />
            </div>

            <div class="inputControl half last">
                <label for="lastName">Last Name: <mark>*</mark></label>
                <input type="text" 
                       id="lastName" 
                       name="lastName" 
                       placeholder="Last Name" 
                       required />
            </div>

            <div class="inputControl">
                <label for="lastName">Testimonial: <mark>*</mark></label>
                <textarea name="testimonial" style="min-height:200px"></textarea>
            </div>

            <fieldset>
                <legend>Recaptcha</legend>
                <div style="float:right;" class="g-recaptcha" :data-sitekey="siteKey"></div>
            </fieldset>

            <fieldset class="alignRight">
                <legend>Submit</legend>
                <button type="submit" class="btn btn-primary btn-large" data-submit-btn>Submit</button>
            </fieldset>

        </fieldset>
    </form>
</template>

<script>
import { mapState } from 'pinia';
import { globalStore } from "../stores/global.js";

export default {
    name: "TestimonialForm",
    components: {},
    props: {
        nonce: {
            type: String,
            required: true
        },
        siteKey: {
            type: String,
            required: true
        }
    },
    mounted() { },
    methods: {},
    computed: {
        ...mapState(globalStore, {
            isUserAuthenticated: store => store.isUserAuthenticated,
            siteKey: store => store.siteKey,
            nonce: store => store.nonces.testimonial
        })
    },
};
</script>

<style>
</style>
