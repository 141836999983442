import { defineStore } from 'pinia';

export const loginStore = defineStore('loginStore', {
  state: () => {
    return {
      nonces: {
        login: login.nonces.login,
      }
    }
  }
});