<template>
  <h2>{{ userGreeting }}</h2>

  <div class="userDetailsBlock">
    <p v-if="detailsLoaded">
      <small>User Name:</small> {{ username }}<br />
      <small>Email:</small> {{ email }}
    </p>
    <GlobalLoader v-if="!detailsLoaded" />
  </div>
</template>

<script>
import { accountStore } from "../stores/account";
import { modalStore } from "../stores/modal";

import GlobalLoader from "../components/GlobalLoader.vue";
import { mapState } from "pinia";

export default {
  name: "UserDetails",
  components: {
    GlobalLoader
  },
  props: {
    uid: {
      type: Number,
      required: true,
      default: null
    }
  },
  mounted() {
    const acs = accountStore();
    acs.loadUserData();
  },
  methods: {},
  computed: {
    userGreeting: function () {
      let greeting = 'Hello, ';

      if (this.firstName) {
        greeting += this.firstName;
      }

      if (this.lastName) {
        greeting += ' ' + this.lastName;
      }

      if (!this.firstName && !this.lastName) {
        greeting += 'User';
      }

      return greeting;
    },
    ...mapState(accountStore, {
      username: store => store.user.username,
      email: store => store.user.email,
      firstName: store => store.user.firstName,
      lastName: store => store.user.lastName,
      detailsLoaded: store => store.detailsLoaded
    })
  }
};
</script>
