<template>

    <div class="container flexed alignTop">
        <div class="secondary">
            <nav class="accountNav">
                <ul>
                    <li><a href="#" @click.prevent="showTab('accountDetails')">Personal Info</a></li>
                    <li><a href="#" @click.prevent="showTab('favoriteListings')">Favorites</a></li>
                    <li><a href="#" @click.prevent="showTab('testimonials')">Testimonials</a></li>
                </ul>
            </nav>
        </div>

        <div class="primary">

            <div class="tab accountDetails" ref="accountDetails">
                <div>
                    <UserDetails :uid="uid" />
                
                    <div class="passwordResetFormWrapper">
                        <h2>Need to update your password?</h2>
                        <hr class="left" />
                        <a href="/reset-password" class="btn btn-large btn-primary">Reset Password</a>
                        
                    </div>
                </div>
            </div>

            <div class="tab favoriteListings" ref="favoriteListings">
                <div class="favoritesList">
                    <h2>Favorite Listings: ({{ favoriteListings.length }})</h2>

                    <div class="">
                        <ul class="container flexed homepageListingGrid alignTop" v-if="this.favoriteListings.length > 0">
                            <li class="listingCard" v-for="listing in this.favoriteListings">
                                <ListingCard :listingData="listing" />
                            </li>
                        </ul>

                        <p v-else>You have no favorited listings currently!</p>
                        <a class="btn btn-small btn-primary" href="/listings">View Listings</a>
                    </div>
                </div>
            </div>

            <div class="tab testimonials" ref="testimonials">
                <h2>Submit a Testimonial</h2>
                <TestimonialForm :nonce="nonce" :siteKey="siteKey" />
            </div>
        </div>
    </div>

</template>

<script>
import { mapState } from 'pinia';

import { accountStore } from "../stores/account.js";
import { globalStore } from "../stores/global.js";

import ListingCard from "../components/ListingCard.vue";
import UserDetails from "../components/UserDetails.vue";
import PasswordResetForm from "../forms/PasswordResetForm.vue"
import TestimonialForm from "../forms/TestimonialForm.vue";

export default {
    name: "MyAccount",
    components: {
        ListingCard,
        UserDetails,
        PasswordResetForm,
        TestimonialForm
    },
    mounted() {
        const acs = accountStore();
        acs.loadUserData();
        acs.loadFavoriteData();
    },
    methods: {
        showTab: function (tabId) {
            const tabs = $('.tab');

            if (tabs.length) {
                tabs.each(function () {
                    const tab = $(this);
                    tab.hide();
                })
            }

            const showTab = $(this.$refs[tabId]);

            if (showTab.length) {
                showTab.show();
            }
        }
    },
    computed: {
        ...mapState(accountStore, {
            favoriteListings: store => store.favoriteListings,
            nonce: store => store.nonce
        }),
        ...mapState(globalStore, {
            uid: store => store.user.uid,
            isUserAuthenticated: store => store.isUserAuthenticated,
            siteKey: store => store.siteKey
        }),
    },
};
</script>

<style>
</style>
